/** @jsxImportSource @emotion/react */

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

type DecorationSectionProps = {};
const DecorationSection: React.FC<DecorationSectionProps> = ({}) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        height: "0px",
        alignItems: "center",
        position: { md: "relative", xs: "absolute" },
        zIndex: 2,
        margin: "auto",
        width: { xs: "100%", xl: "90%" },
      }}
    >
      <Box
        className="headerDeco1"
        sx={{
          position: "relative",
          transform: {
            xs: "translate(0px, -10px)",
            md: "translate(-10px, 0%)",
          },
          zIndex: 2,
        }}
      >
        <StaticImage src="../../images/modulesIllustration_5.png" alt="" />
      </Box>
      <Box
        className="headerDeco2"
        sx={{
          position: "relative",
          display: { xs: "none", md: "block" },
          width: "80px",
          marginRight: "80px",
          alignSelf: "flex-start",
          zIndex: 2,
        }}
      >
        <StaticImage src="../../images/footerBlueSphere.svg" alt="" />
      </Box>
    </Box>
  );
};
export default DecorationSection;
