import { Box, useMediaQuery, useTheme } from "@mui/material";

import BigCentralImage from "./Header/BigCentralImage";
import DecorationSection from "./Header/DecorationSection";
import Title from "./Header/Title";

const Header = ({}) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        marginTop: "100px",
      }}
    >
      <Title />
      {isOverMd && <DecorationSection />}
      <BigCentralImage />
    </Box>
  );
};
export default Header;
