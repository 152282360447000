/** @jsxImportSource @emotion/react */

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { l_bonusx } from "../../locales";
import BonusxTypography from "../Theme/BonusxTypography";

const Description = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box
      sx={{
        // border: "1px solid pink",
        position: { md: "absolute", xs: "static" },
        maxWidth: { md: "45%", xs: "100%" },
        padding: { md: "24px", xs: "40px 0px" },
        top: 0,
        right: "calc(12% - 24px)",
      }}
    >
      <div
        css={{
          width: "50%",
          height: "6px",
          backgroundColor: "#1A59E7",
          borderRadius: "10px",
          marginBottom: isOverLg ? "24px" : "12px",
          marginTop: isOverLg ? "8px" : "-8px",
          display: isOverMd ? "block" : "none",
        }}
      ></div>
      <BonusxTypography
        variant="description"
        css={{
          marginRight: isOverLg ? "20px" : 0,
        }}
      >
        {l_bonusx("frontend.description")}
      </BonusxTypography>
    </Box>
  );
};

export default Description;
