import { Box, useMediaQuery, useTheme } from "@mui/material";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import ConcentricCircles from "../ConcentricCircles";

const CirclesSection = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    // <Box
    //   sx={{
    //     margin: "auto",
    //     width: "80%",
    //     // width: "500px",
    //     boxSizing: "border-box",
    //     backgroundColor: "yellow",
    //     height: "100px",
    //   }}
    // >
    <Box sx={{ position: "relative", zIndex: 1 }}>
      <MabiParallax
        id="abcd"
        duration={2}
        tweens={[
          {
            selector: ".reviewCirclesLeft",
            from: {
              scale: 1.3,
            },
            to: {
              scale: 0.8,
            },
          },
          {
            selector: ".reviewCirclesRight",
            from: {
              scale: 1.3,
            },
            to: {
              scale: 0.8,
            },
          },
        ]}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "80%",
            maxWidth: "500px",
            minWidth: "350px",
            // // backgroundColor: "darkorange",
            // top: 0,
            transform: { xs: "translate(0, -10%)", md: "translate(-50%, 25%)" },
            display: { md: "block", xs: "block" },
            margin: "auto",
          }}
          className={"reviewCirclesLeft"}
        >
          <ConcentricCircles
            gap={{ xs: 30, md: 50 }}
            nCircles={{ xs: 10, md: 7 }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "80%",
            top: { md: 0, xs: "unset" },
            bottom: { xs: 0, md: "unset" },
            transform: { xs: "translateY(10%)", md: "translate(25%, 25%)" },
            maxWidth: "500px",
            minWidth: "350px",
            display: { md: "block", xs: "block" },
            boxSizing: "border-box",
            margin: "auto",
          }}
          className={"reviewCirclesRight"}
        >
          <ConcentricCircles
            gap={{ xs: 30, md: 50 }}
            nCircles={{ xs: 10, md: 7 }}
          />
        </Box>
      </MabiParallax>
    </Box>
  );
};
export default CirclesSection;
