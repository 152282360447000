/** @jsxImportSource @emotion/react */
import { Grid } from "@mui/material";
import Image from "../../../components/image";
import SEO from "../../../components/old/seo";
import { BodyPadding } from "../../../components/shared/body_utils";
import DarkSide from "../../../components/shared/DarkSide";
import FooterContent from "../../../components/shared/FooterContent";
import { ress } from "../../../components/shared/mtext";
import { SplitAbsoluteTopText } from "../../../components/shared/split_absolute_top_text";
import { PageProps } from "../../../components/utils/PageProps";
import Header from "./components/Header";
import BonusXFooter from "./components/Footer";
import EasyBurocracySection from "./components/EasyBurocracySection";
import NumbersCountSection from "./components/NumbersCountSection";
import ReviewsSection from "./components/ReviewsSection";
import FrontendDevSection from "./components/FrontendDevSection";
import BackendDevSection from "./components/BackendDevSection";
import { L } from "../../../traductions";
import { MabiPartners } from "../../../components/shared/MabiPartners";
import Footer from "../../../components/shared/Footer/Footer";
import PrevNextCaseStudy from "../../../components/shared/PrevNextCaseStudy/PrevNextCaseStudy";
import { l_bonusx } from "./locales";

const BonusX = (props: PageProps) => {
  return (
    <>
      <SEO
        title={l_bonusx("seo.title")}
        description={l_bonusx("seo.description")}
        image="/case_studies_png/bonusx.png"
      />
      {/* <img src={require("./images/sgeeks_4.png")} />
        <div css={{ width: 200, height: 100 }}> */}
      {/* <Image filename="casestudy/bonusx/a_sgeeks_4.png" /> */}

      {/* <div
        css={{
          padding: 100,
          background: "red",

          ...ress("sm+", { background: "orange" }),
        }}
      /> */}

      {/* <BodyPadding> */}
      <Header />
      <EasyBurocracySection />
      <NumbersCountSection />
      <ReviewsSection />
      <FrontendDevSection />
      <BackendDevSection />
      <BonusXFooter />
      <PrevNextCaseStudy />
      {/* </BodyPadding> */}
      <Footer />
    </>
  );
};

export default BonusX;
